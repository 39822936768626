// DropdownItem.js
import React from 'react';
import { StyledNavLinks } from '../styles/Navbar.styled'; // Make sure to import your styled components

const DropdownItem = ({ to, smooth, children }) => {
  return (
    <StyledNavLinks to={to} smooth={smooth}>
      {children}
    </StyledNavLinks>
  );
};

export default DropdownItem;
