import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  ArrowToTop,
  ContactHeader,
  ContactLink,
  Copyright,
  FooterArrowLine,
  FooterBigLink,
  FooterBigLinkContainer,
  FooterContact,
  FooterContacts,
  FooterLine,
  FooterSmallTitle,
  FooterSmallTitleWrapper,
  FooterSocials,
  Small,
  StyledFooter,
} from "../styles/Footer.styled";

const toTop = () => {
  scroll.scrollToTop({ delay: 0, duration: 0 });
};

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <StyledFooter>
      <FooterSmallTitleWrapper>
        <FooterSmallTitle>get in touch</FooterSmallTitle>
      </FooterSmallTitleWrapper>

      <FooterBigLinkContainer>
        <FooterBigLink
          href="mailto:sahil@geekmeo.xyz"
          data-hover="Let's talk!"
        >
          Want to be a Contributor?
        </FooterBigLink>
      </FooterBigLinkContainer>
      <FooterContacts>
        <FooterContact>
          <ContactHeader>Check Out</ContactHeader>
          <ContactLink href="https://www.youtube.com/@Geekmeo" target="_blank" rel="noopener noreferrer">
            Youtube
          </ContactLink>
        </FooterContact>
        <FooterContact>
          <ContactHeader>Social</ContactHeader>{" "}
          <FooterSocials>
            <ContactLink
              href="https://github.com/sahiljagtap08"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </ContactLink>
            <ContactLink
              href="https://www.linkedin.com/company/geekmeo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </ContactLink>
            <ContactLink
              href="https://instagram.com/geekmeo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </ContactLink>
          </FooterSocials>
        </FooterContact>
        <FooterContact>
          <ContactHeader>Email Address</ContactHeader>
          <ContactLink href="mailto: sahil@geekmeo.xyz">
            sahil@geekmeo.xyz
          </ContactLink>
        </FooterContact>
      </FooterContacts>

      <FooterArrowLine>
        <FooterLine />
        <ArrowToTop title="Back to Top" onClick={toTop}></ArrowToTop>
      </FooterArrowLine>

      <Copyright>
        <small>
          &copy; Copyright {year},{" "}
          <Small
            href="https://geekmeo.xyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            Geekmeo
          </Small>
        </small>
      </Copyright>
    </StyledFooter>
  );
};

export default Footer;