import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Projects from "./components/Projects/Projects";
import GlobalStyles from "./components/styles/Global";
import { ThemeProvider } from "styled-components";
import { theme } from "./components/styles/Theme";
import { BrowserRouter as Router } from "react-router-dom";
import ParallaxComponent from "./components/Parallax/Parallax";
import { useState } from "react";

function App() {
  console.log(
    "%cThank you for checking Geekmeo. Wishing you the best for every step in your journey!",
    "color: white; font-weight: 500; font-size:16px"
  );
  console.log(
    "%cYou can check my github here https://github.com/sahiljagtap08",
    "color: white; font-weight: 500; font-size:16px"
  );

  const [open, setOpen] = useState(true);

  const toggleHandler = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Navbar />
          <Home />
          <Projects />
        <About/>
          <ParallaxComponent />
          <Footer />
        </>
      </ThemeProvider>
    </Router>
  );
}

export default App;
