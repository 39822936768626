import React from "react";
import { IconContext } from "react-icons";
import { FaEnvelope, FaInstagram } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import { SiDiscord, SiYoutube } from "react-icons/si";
import img from "../../assets/homepage.svg";
import aboutIcon from "../../assets/icons/about-me.svg";
import projectIcon from "../../assets/icons/project-icon.svg";
import logo from "../../assets/logo.svg";
import { AboutImage, AboutImageContainer } from "../styles/About.styled";
import { StyledButton } from "../styles/Button.styled";
import {
  Greeting,
  Line,
  Name,
  Quote,
  Social,
  Socials,
  StyledButtonsContainer,
  StyledHome,
  TextContainer,
  Title,
} from "../styles/Home.styled";
import "./style.css";

const Home = () => {
  const getRandomMessage = () => {
    const messages = [
      "Algorithms are Important!",
      "Master DSA!",
      "Become a GOAT problem solver!",
      "Just because its $0/month, it doesn't mean we are compromising in quality!",
    ];
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  };

  const creativeMessage = getRandomMessage();
  return (
    
    <IconContext.Provider value={{ size: "1rem" }}>
      
      <StyledHome id="home">
        <Name>Welcome to</Name>
        <Title style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ width: '350px', height: 'auto', marginRight: '0px' }} />
          <span className="responsive">.x</span>yz
        </Title>
        <TextContainer> <p>
          For geeks interested in <b>Computer Science</b> & algorithmic problem solving.
        </p></TextContainer>
       
        <AboutImageContainer className="about-image-container" style={{ position: 'absolute', right: '20px', top: '42%', transform: 'translateY(-50%)' }}>
          <AboutImage
            src={img} 
            style={{ width: '350px', height: 'auto' }}
          />
        </AboutImageContainer>
        <StyledButtonsContainer>
          <StyledButton
            icon={projectIcon}
            to="projects"
            smooth={true}
            duration={0}
            delay={0}
            spy={true}
            spyThrottle={0}
            exact="true"
            offset={-50}
          >
            <span>Courses</span>
          </StyledButton>
          <StyledButton
            icon={aboutIcon}
            secondary="true"
            content="B"
            href="https://docs.google.com/forms/d/1dNOwFjVo_1a85ZReGJe1KntqgWvEdmJaEpqgk-E3a9M/"
            target="_blank"
            rel="noopener noreferrer"
            smooth={true}
            duration={0}
            delay={0}
            spy={true}
            spyThrottle={0}
            exact="true"
            offset={-50}
          >
            <span>Contribute</span>
          </StyledButton>
        </StyledButtonsContainer>
        <Line />
        <Greeting>
          {creativeMessage}{" "}
        </Greeting>
        <Socials>
          
          
          <Social
            href="https://www.linkedin.com/company/geekmeo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GrLinkedinOption />
          </Social>
          <Social
            href="https://youtube.com/@Geekmeo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiYoutube />
          </Social>
          <Social
            href="https://instagram.com/geekmeo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </Social>
          
        </Socials>
        <Quote>CS , Math, Algorithms & Problem Solving!</Quote>
      </StyledHome>
    </IconContext.Provider>
  );
};

export default Home;
