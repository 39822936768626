import React from "react";
import roadmap from "../../assets/Roadmap.pdf";
import downloadIcon from "../../assets/icons/download-icon.svg";
import myImage from "../../assets/my-image.png";
import {
  AboutContent,
  AboutDetailsContainer,
  AboutImage,
  AboutImageContainer,
  Roadmap,
  RoadmapLink,
  StyledAbout,
} from "../styles/About.styled";
import { SectionHeading, SectionTitle } from "../styles/SectionHeading";
import { StyledParagraph } from "../styles/Typography.styled";

const About = () => {
  return (
    <StyledAbout id="about">
      <SectionHeading dark="true" mb="3rem">
        <SectionTitle dark="true" number="02">
          About Geekmeo
        </SectionTitle>
      </SectionHeading>
      <AboutContent>
        <AboutImageContainer>
          <AboutImage src={myImage} />
        </AboutImageContainer>
        <AboutDetailsContainer>
          <StyledParagraph>
            At <b>Geekmeo</b>, we are fueled by the belief in the power of community and the transformative potential of Computer Science education. Our mission is simple yet profound – to give back to the <b>CS community</b>. We are committed to providing free resources, mentorship, and unwavering support to peers and students who aspire to carve a successful career in the dynamic world of technology.
          </StyledParagraph>
          <StyledParagraph>
            <b>Services We Offer without breaking your bank:</b>
            <br></br>
            <br></br>
            <ul>
              <li>Mock Interviews,</li>
              <li>Resume Reviews and Personalized Feedback,</li>
              <li>Resources Galore,</li>
              <li>Talks with Mentors,</li>
              <li>Quality Courses,</li>
              <li>Tech Interview Help,</li>
              <li>Fresh Job Listings and more...</li>
            </ul>
          </StyledParagraph>
          <StyledParagraph>
            A quote that resonates with us is, "<b>The secret of getting ahead is getting started</b>" by Mark Twain.
          </StyledParagraph>
          <Roadmap>
            <RoadmapLink
              href={roadmap}
              icon={downloadIcon}
              download="resume-sahil-jagtap"
            >
              Download CS Roadmap
            </RoadmapLink>
          </Roadmap>
        </AboutDetailsContainer>
      </AboutContent>
    </StyledAbout>
  );
};

export default About;