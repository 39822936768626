import {
  project1Laptop,
  project2Laptop,
  project3Laptop,
  project4Laptop,
} from "./projectImages";

export const projectData = [
  {
    id: 1,
    image: project1Laptop,
    title: "Master Binary Search (Coming Soon)",
    codeLink: "https://www.youtube.com/@Geekmeo/featured",
    
  },
  {
    id: 2,
    image: project2Laptop,
    title: "Sliding Window: Become the GOAT (Coming Soon)",
    codeLink: "https://www.youtube.com/@Geekmeo/featured",
  },
  {
    id: 3,
    image: project3Laptop,
    title: "Dynamic Programming God (Coming Soon)",
    codeLink: "https://www.youtube.com/@Geekmeo/featured",
  },
  {
    id: 4,
    image: project4Laptop,
    title: "Recursion Recursion Recursion Recursion Recur...(Coming Soon)",
    codeLink: "https://www.youtube.com/@Geekmeo/featured",
  },

];
